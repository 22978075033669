import styled from 'styled-components';

export const PrivacyPolicySection = styled.section`
  padding: 32px 0;

  @media (min-width: 992px) {
    padding: 48px 0;
  }

  p {
    margin-top: 16px;
    color: ${({ theme }) => theme.colors.sapphireBlue};
  }

  p:first-child {
    @media (min-width: 992px) {
      margin-top: 0;
    }
  }
`;

export const PrivacyPolicyContent = styled.section`
	color: ${({ theme }) => theme.colors.sapphireBlue};
	p:first-child {
		margin-bottom: 16px;
	}

	p:not(:first-child) {
		margin: 16px 0;
	}

	h2 {
		margin: 26.560px 0;
	}

	h3 {
		margin: 18.720px 0;
	}

	h4 {
		margin: 21.280px 0;
	}

	ul {
		padding-left: 40px;
		margin: 16px 0;
	}
`;

export const StyledH1 = styled.h1`
  color: ${({ theme }) => theme.colors.sapphireBlue};
  margin-bottom: 24px;

  @media (min-width: 992px) {
    margin-bottom: 48px;
  }
`;
