import React from "react"
import { graphql } from "gatsby"
import { INLINES, BLOCKS, MARKS } from "@contentful/rich-text-types"
import { renderRichText } from "gatsby-source-contentful/rich-text"
import Layout from '../components/layout'
import SEO from "../components/seo"
import parse from 'html-react-parser'
import Img from "gatsby-image"

import { Container, Row, Col } from 'react-bootstrap';

import { PrivacyPolicySection, PrivacyPolicyContent, StyledH1 } from '../styles/privacyPolicyStyles';

const privacyPolicy = ({ data }) => {
	// const { fluid: bioPic, title: bioPicTitle } = data.contentfulBioPage.bioProfilePicture;
	const privacyPolicyRichText = data.contentfulPrivacyPolicy.privacyPolicyContent;


	const Text = ({ children }) => (
		<p style={{ whiteSpace: "pre-line" }}>{children}</p>
	);

	const options = {
		renderNode: {
			[BLOCKS.PARAGRAPH]: (node, children) => <Text>{children}</Text>,
			[INLINES.HYPERLINK]: (node, children) => {
				const { uri } = node.data
				return (
					<a href={uri} className="underline">
						{children}
					</a>
				)
			},
			[BLOCKS.HEADING_2]: (node, children) => {
				return <h2>{children}</h2>
			},
			[BLOCKS.HEADING_3]: (node, children) => {
				return <h3>{children}</h3>
			},
			[BLOCKS.HEADING_4]: (node, children) => {
				return <h4>{children}</h4>
			},
		},
	};

	return (
		<Layout>
			<SEO title="Privacy Policy" />
			<PrivacyPolicySection>
				<Container>
					<Row>
						<Col xs={12}>
							<StyledH1>Privacy Policy</StyledH1>
								<PrivacyPolicyContent>
									{renderRichText(privacyPolicyRichText, options)}
								</PrivacyPolicyContent>
						</Col>
					</Row>
				</Container>
			</PrivacyPolicySection>
		</Layout>
	);
}

export default privacyPolicy;

export const pageQuery = graphql`
  query privacyPolicyQuery {
   	contentfulPrivacyPolicy {
			privacyPolicyContent {
				raw
			}
		}
  }
`
